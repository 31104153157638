// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "assets/styles/variables/variables";
@import "assets/styles/template/angular-material-theme.scss";

/* You can add global styles to this file, and also import other style files */
html {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  height: 100%;
}

body {
  height: 100%;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h2 {
  @include headline2();
  color: $color-blue-dark;
  margin: 0 !important;
}

.subtitle {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: $color-blue-dark;
}

@import "assets/font/admcs/style";
@import "assets/styles/template/template";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "leaflet/dist/leaflet.css";
