@font-face {
	font-family: Lato, sans-serif;
}

$global-font-family: Lato, sans-serif;

$menu-transition-time: .3s;

$header-height: 3rem;

// Small devices (landscape phones, 576px and up)
$screen-sm: 576px;
// Medium devices (tablets, 768px and up)
$screen-md: 768px;
// Large devices (desktops, 992px and up)
$screen-lg: 992px;
// Extra large devices (large desktops, 1200px and up)
$screen-xl: 1200px;
