@font-face {
  font-family: 'admcs';
  src:  url('/assets/font/admcs/fonts/admcs.eot?ijswk0');
  src:  url('/assets/font/admcs/fonts/admcs.eot?ijswk0#iefix') format('embedded-opentype'),
  url('/assets/font/admcs/fonts/admcs.ttf?ijswk0') format('truetype'),
  url('/assets/font/admcs/fonts/admcs.woff?ijswk0') format('woff'),
  url('/assets/font/admcs/fonts/admcs.svg?ijswk0#admcs') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'admcs', serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-private:before {
  content: "\e900";
}
.icon-add:before {
  content: "\e901";
}
.icon-add-contact:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-arrow-light-up:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-barge:before {
  content: "\e906";
}
.icon-barge_b .path1:before {
  content: "\e907";
  color: rgb(255, 255, 255);
}
.icon-barge_b .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-barge_b .path3:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-barge_b .path4:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-barge_b .path5:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-barge_b .path6:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-barge_c .path1:before {
  content: "\e90d";
  color: rgb(0, 0, 0);
}
.icon-barge_c .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-barge_c .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-barge_c .path4:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-barge_c .path5:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-barge_c .path6:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-barge_g .path1:before {
  content: "\e913";
  color: rgb(0, 0, 0);
}
.icon-barge_g .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-barge_g .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-barge_g .path4:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-barge_g .path5:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-barge_g_1 .path1:before {
  content: "\e918";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-barge_g_1 .path2:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-barge_g_1 .path3:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-barge_g_1 .path4:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-barge_g_1 .path5:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-barge_g_1 .path6:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-bloc:before {
  content: "\e91e";
}
.icon-boat .path1:before {
  content: "\e91f";
  color: rgb(29, 29, 27);
}
.icon-boat .path2:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-boat .path3:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-boat .path4:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-boat .path5:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-boat .path6:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-burger-menu:before {
  content: "\e925";
}
.icon-calendar:before {
  content: "\e926";
}
.icon-carret_down:before {
  content: "\e927";
}
.icon-carret_up:before {
  content: "\e928";
}
.icon-check:before {
  content: "\e929";
}
.icon-clock:before {
  content: "\e92a";
}
.icon-close:before {
  content: "\e92b";
}
.icon-close-list:before {
  content: "\e92c";
}
.icon-comment:before {
  content: "\e92d";
}
.icon-conatianer_return_c .path1:before {
  content: "\e92e";
  color: rgb(0, 0, 0);
}
.icon-conatianer_return_c .path2:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-conatianer_return_c .path3:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-conatianer_return_c .path4:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-conatianer_return_c .path5:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-conatianer_return_c .path6:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-conatianer_return_c .path7:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-conatianer_return_c .path8:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-conatianer_return_c .path9:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-conatianer_return_c .path10:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-conatianer_return_c .path11:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-contact:before {
  content: "\e939";
}
.icon-container_b .path1:before {
  content: "\e93a";
  color: rgb(255, 255, 255);
}
.icon-container_b .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_b .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_b .path4:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_b .path5:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_b .path6:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_b .path7:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_b .path8:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_b .path9:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_b .path10:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_c .path1:before {
  content: "\e944";
  color: rgb(0, 0, 0);
}
.icon-container_c .path2:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_c .path3:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_c .path4:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_c .path5:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_c .path6:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_c .path7:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_c .path8:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_c .path9:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_c .path10:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_g .path1:before {
  content: "\e94e";
  color: rgb(255, 255, 255);
}
.icon-container_g .path2:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_g .path3:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_g .path4:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_g .path5:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_g .path6:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_g .path7:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_g .path8:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_g .path9:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_g .path10:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_g_1 .path1:before {
  content: "\e958";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-container_g_1 .path2:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_g_1 .path3:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_g_1 .path4:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_g_1 .path5:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_g_1 .path6:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_g_1 .path7:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_g_1 .path8:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_g_1 .path9:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_g_1 .path10:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_b .path1:before {
  content: "\e962";
  color: rgb(255, 255, 255);
}
.icon-container_pickup_b .path2:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_b .path3:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_b .path4:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_b .path5:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_b .path6:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_b .path7:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_pickup_b .path8:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_pickup_b .path9:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_pickup_c .path1:before {
  content: "\e96b";
  color: rgb(0, 0, 0);
}
.icon-container_pickup_c .path2:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_c .path3:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_c .path4:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_c .path5:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_c .path6:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_c .path7:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_c .path8:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_c .path9:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_pickup_g .path1:before {
  content: "\e974";
  color: rgb(255, 255, 255);
}
.icon-container_pickup_g .path2:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_pickup_g .path3:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_pickup_g .path4:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_pickup_g .path5:before {
  content: "\e978";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_pickup_g .path6:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_pickup_g .path7:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_pickup_g .path8:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_pickup_g .path9:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_pickup_g_1 .path1:before {
  content: "\e97d";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-container_pickup_g_1 .path2:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_b .path1:before {
  content: "\e97f";
  color: rgb(255, 255, 255);
}
.icon-container_return_b .path2:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_return_b .path3:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_return_b .path4:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_return_b .path5:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_return_b .path6:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_b .path7:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_b .path8:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_b .path9:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_b .path10:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_b .path11:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_g .path1:before {
  content: "\e98a";
  color: rgb(255, 255, 255);
}
.icon-container_return_g .path2:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(243, 243, 241);
}
.icon-container_return_g .path3:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(243, 243, 241);
}
.icon-container_return_g .path4:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(243, 243, 241);
}
.icon-container_return_g .path5:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(243, 243, 241);
}
.icon-container_return_g .path6:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_return_g .path7:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_return_g .path8:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_return_g .path9:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-container_return_g .path10:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_return_g .path11:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_return_g .path12:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_return_g .path13:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_return_g .path14:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_return_g .path15:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-container_return_g_1 .path1:before {
  content: "\e999";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-container_return_g_1 .path2:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_g_1 .path3:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_g_1 .path4:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_g_1 .path5:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_g_1 .path6:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_g_1 .path7:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_g_1 .path8:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_g_1 .path9:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_g_1 .path10:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-container_return_g_1 .path11:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_b .path1:before {
  content: "\e9a4";
  color: rgb(255, 255, 255);
}
.icon-customer_b .path2:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-customer_b .path3:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_b .path4:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_b .path5:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_b .path6:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_b .path7:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_b .path8:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_c .path1:before {
  content: "\e9ac";
  color: rgb(0, 0, 0);
}
.icon-customer_c .path2:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_c .path3:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_c .path4:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_c .path5:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_c .path6:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_c .path7:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_c .path8:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_g .path1:before {
  content: "\e9b4";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-customer_g .path2:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_g .path3:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_g .path4:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_g .path5:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_g .path6:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_g .path7:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer_g .path8:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer-created .path1:before {
  content: "\e9bc";
  color: rgb(8, 15, 54);
}
.icon-customer-created .path2:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-customer-created .path3:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-customer-created .path4:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-customer-created .path5:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-customer-created .path6:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-customer-created .path7:before {
  content: "\e9c2";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer-created .path8:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer-created .path9:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customer-created .path10:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-customized .path1:before {
  content: "\e9c6";
  color: rgb(135, 136, 138);
}
.icon-customized .path2:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(201, 201, 201);
}
.icon-customized .path3:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(201, 201, 201);
}
.icon-customized .path4:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(201, 201, 201);
}
.icon-customized .path5:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(201, 201, 201);
}
.icon-customized .path6:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(201, 201, 201);
}
.icon-delete:before {
  content: "\e9cc";
}
.icon-ecological .path1:before {
  content: "\e9cd";
  color: rgb(135, 136, 138);
}
.icon-ecological .path2:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(201, 201, 201);
}
.icon-ecological .path3:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(201, 201, 201);
}
.icon-ecological .path4:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(135, 136, 138);
}
.icon-ecological .path5:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(135, 136, 138);
}
.icon-economical .path1:before {
  content: "\e9d2";
  color: rgb(135, 136, 138);
}
.icon-economical .path2:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(135, 136, 138);
}
.icon-economical .path3:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(201, 201, 201);
}
.icon-edit:before {
  content: "\e9d5";
}
.icon-everyone:before {
  content: "\e9d6";
}
.icon-filter:before {
  content: "\e9d7";
}
.icon-help:before {
  content: "\e9d8";
}
.icon-link:before {
  content: "\e9d9";
}
.icon-list:before {
  content: "\e9da";
}
.icon-logout_b .path1:before {
  content: "\e9db";
  color: rgb(255, 255, 255);
}
.icon-logout_b .path2:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-logout_b .path3:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-logout_b .path4:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-logout_b .path5:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-logout_b .path6:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-logout_b .path7:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_b .path8:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_c .path1:before {
  content: "\e9e3";
  color: rgb(0, 0, 0);
}
.icon-logout_c .path2:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_c .path3:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_c .path4:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_c .path5:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_c .path6:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_c .path7:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_c .path8:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_g .path1:before {
  content: "\e9eb";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-logout_g .path2:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_g .path3:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_g .path4:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_g .path5:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_g .path6:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_g .path7:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-logout_g .path8:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-merchandise_pickup_b .path1:before {
  content: "\e9f3";
  color: rgb(255, 255, 255);
}
.icon-merchandise_pickup_b .path2:before {
  content: "\e9f4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-merchandise_pickup_b .path3:before {
  content: "\e9f5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-merchandise_pickup_b .path4:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-merchandise_pickup_b .path5:before {
  content: "\e9f7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-merchandise_pickup_b .path6:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-merchandise_pickup_c .path1:before {
  content: "\e9f9";
  color: rgb(0, 0, 0);
}
.icon-merchandise_pickup_c .path2:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-merchandise_pickup_c .path3:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-merchandise_pickup_c .path4:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-merchandise_pickup_c .path5:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-merchandise_pickup_c .path6:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-merchandise_pickup_g .path1:before {
  content: "\e9ff";
  color: rgb(255, 255, 255);
}
.icon-merchandise_pickup_g .path2:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-merchandise_pickup_g .path3:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-merchandise_pickup_g .path4:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-merchandise_pickup_g .path5:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-merchandise_pickup_g .path6:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-merchandise_pickup_g_1 .path1:before {
  content: "\ea05";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-merchandise_pickup_g_1 .path2:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-merchandise_pickup_g_1 .path3:before {
  content: "\ea07";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-merchandise_pickup_g_1 .path4:before {
  content: "\ea08";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-merchandise_pickup_g_1 .path5:before {
  content: "\ea09";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-merchandise_pickup_g_1 .path6:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-more-option:before {
  content: "\ea0b";
}
.icon-myprofile_b .path1:before {
  content: "\ea0c";
  color: rgb(255, 255, 255);
}
.icon-myprofile_b .path2:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-myprofile_b .path3:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-myprofile_b .path4:before {
  content: "\ea0f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-myprofile_b .path5:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_b .path6:before {
  content: "\ea11";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_b .path7:before {
  content: "\ea12";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_c .path1:before {
  content: "\ea13";
  color: rgb(0, 0, 0);
}
.icon-myprofile_c .path2:before {
  content: "\ea14";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_c .path3:before {
  content: "\ea15";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_c .path4:before {
  content: "\ea16";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_c .path5:before {
  content: "\ea17";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_c .path6:before {
  content: "\ea18";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_c .path7:before {
  content: "\ea19";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_g .path1:before {
  content: "\ea1a";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-myprofile_g .path2:before {
  content: "\ea1b";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_g .path3:before {
  content: "\ea1c";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_g .path4:before {
  content: "\ea1d";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_g .path5:before {
  content: "\ea1e";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_g .path6:before {
  content: "\ea1f";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-myprofile_g .path7:before {
  content: "\ea20";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-notif_b .path1:before {
  content: "\ea21";
  color: rgb(255, 255, 255);
}
.icon-notif_b .path2:before {
  content: "\ea22";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-notif_b .path3:before {
  content: "\ea23";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-notif_b .path4:before {
  content: "\ea24";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-notif_b .path5:before {
  content: "\ea25";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-notif_c .path1:before {
  content: "\ea26";
  color: rgb(0, 0, 0);
}
.icon-notif_c .path2:before {
  content: "\ea27";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-notif_c .path3:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-notif_c .path4:before {
  content: "\ea29";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-notif_c .path5:before {
  content: "\ea2a";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-notif_g .path1:before {
  content: "\ea2b";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-notif_g .path2:before {
  content: "\ea2c";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-notif_g .path3:before {
  content: "\ea2d";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-notif_g .path4:before {
  content: "\ea2e";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-notif_g .path5:before {
  content: "\ea2f";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-open-list:before {
  content: "\ea30";
}
.icon-pin:before {
  content: "\ea31";
}
.icon-Plan-de-travail-10 .path1:before {
  content: "\ea32";
  color: rgb(0, 0, 0);
}
.icon-Plan-de-travail-10 .path2:before {
  content: "\ea33";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Plan-de-travail-10 .path3:before {
  content: "\ea34";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Plan-de-travail-10 .path4:before {
  content: "\ea35";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Plan-de-travail-10 .path5:before {
  content: "\ea36";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-research:before {
  content: "\ea38";
}
.icon-services:before {
  content: "\ea39";
}
.icon-settings_b .path1:before {
  content: "\ea3a";
  color: rgb(255, 255, 255);
}
.icon-settings_b .path2:before {
  content: "\ea3b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-settings_b .path3:before {
  content: "\ea3c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-settings_b .path4:before {
  content: "\ea3d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-settings_b .path5:before {
  content: "\ea3e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-settings_b .path6:before {
  content: "\ea3f";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_b .path7:before {
  content: "\ea40";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_c .path1:before {
  content: "\ea41";
  color: rgb(0, 0, 0);
}
.icon-settings_c .path2:before {
  content: "\ea42";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_c .path3:before {
  content: "\ea43";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_c .path4:before {
  content: "\ea44";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_c .path5:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_c .path6:before {
  content: "\ea46";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_c .path7:before {
  content: "\ea47";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_g .path1:before {
  content: "\ea48";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-settings_g .path2:before {
  content: "\ea49";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_g .path3:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_g .path4:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_g .path5:before {
  content: "\ea4c";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_g .path6:before {
  content: "\ea4d";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-settings_g .path7:before {
  content: "\ea4e";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-ship-container_b .path1:before {
  content: "\ea4f";
  color: rgb(255, 255, 255);
}
.icon-ship-container_b .path2:before {
  content: "\ea50";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ship-container_b .path3:before {
  content: "\ea51";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ship-container_b .path4:before {
  content: "\ea52";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ship-container_b .path5:before {
  content: "\ea53";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ship-container_b .path6:before {
  content: "\ea54";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-ship-container_c .path1:before {
  content: "\ea55";
  color: rgb(0, 0, 0);
}
.icon-ship-container_c .path2:before {
  content: "\ea56";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-ship-container_c .path3:before {
  content: "\ea57";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-ship-container_c .path4:before {
  content: "\ea58";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-ship-container_c .path5:before {
  content: "\ea59";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-ship-container_c .path6:before {
  content: "\ea5a";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-ship-container_g .path1:before {
  content: "\ea5b";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-ship-container_g .path2:before {
  content: "\ea5c";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-ship-container_g .path3:before {
  content: "\ea5d";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-ship-container_g .path4:before {
  content: "\ea5e";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-ship-container_g .path5:before {
  content: "\ea5f";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-ship-container_g .path6:before {
  content: "\ea60";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-Sirius-sans-baseline-blanc-symbolecolor .path1:before {
  content: "\ea61";
  color: rgb(255, 255, 255);
}
.icon-Sirius-sans-baseline-blanc-symbolecolor .path2:before {
  content: "\ea62";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Sirius-sans-baseline-blanc-symbolecolor .path3:before {
  content: "\ea63";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Sirius-sans-baseline-blanc-symbolecolor .path4:before {
  content: "\ea64";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Sirius-sans-baseline-blanc-symbolecolor .path5:before {
  content: "\ea65";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Sirius-sans-baseline-blanc-symbolecolor .path6:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Sirius-sans-baseline-blanc-symbolecolor .path7:before {
  content: "\ea67";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Sirius-sans-baseline-blanc-symbolecolor .path8:before {
  content: "\ea68";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Sirius-sans-baseline-blanc-symbolecolor .path9:before {
  content: "\ea69";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Sirius-sans-baseline-blanc-symbolecolor .path10:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-smiley-happy:before {
  content: "\ea6b";
}
.icon-smiley-neutral:before {
  content: "\ea6c";
}
.icon-smiley-sad:before {
  content: "\ea6d";
}
.icon-train:before {
  content: "\ea6e";
}
.icon-train_b .path1:before {
  content: "\ea6f";
  color: rgb(255, 255, 255);
}
.icon-train_b .path2:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-train_b .path3:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-train_b .path4:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-train_b .path5:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-train_b .path6:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-train_c .path1:before {
  content: "\ea75";
  color: rgb(0, 0, 0);
}
.icon-train_c .path2:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-train_c .path3:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-train_c .path4:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-train_c .path5:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-train_c .path6:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-train_g .path1:before {
  content: "\ea7b";
  color: rgb(107, 119, 140);
}
.icon-train_g .path2:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-train_g .path3:before {
  content: "\ea7d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-train_g .path4:before {
  content: "\ea7e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-train_g .path5:before {
  content: "\ea7f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-train_g_1 .path1:before {
  content: "\ea80";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-train_g_1 .path2:before {
  content: "\ea81";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-train_g_1 .path3:before {
  content: "\ea82";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-train_g_1 .path4:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-train_g_1 .path5:before {
  content: "\ea84";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-train_g_1 .path6:before {
  content: "\ea85";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck:before {
  content: "\ea86";
}
.icon-truck_b .path1:before {
  content: "\ea87";
  color: rgb(255, 255, 255);
}
.icon-truck_b .path2:before {
  content: "\ea88";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_b .path3:before {
  content: "\ea89";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_b .path4:before {
  content: "\ea8a";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_b .path5:before {
  content: "\ea8b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-truck_b .path6:before {
  content: "\ea8c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-truck_b .path7:before {
  content: "\ea8d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-truck_b .path8:before {
  content: "\ea8e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-truck_c .path1:before {
  content: "\ea8f";
  color: rgb(0, 0, 0);
}
.icon-truck_c .path2:before {
  content: "\ea90";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_c .path3:before {
  content: "\ea91";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_c .path4:before {
  content: "\ea92";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_c .path5:before {
  content: "\ea93";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_c .path6:before {
  content: "\ea94";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_c .path7:before {
  content: "\ea95";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_c .path8:before {
  content: "\ea96";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_g .path1:before {
  content: "\ea97";
  color: rgb(255, 255, 255);
}
.icon-truck_g .path2:before {
  content: "\ea98";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-truck_g .path3:before {
  content: "\ea99";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-truck_g .path4:before {
  content: "\ea9a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-truck_g .path5:before {
  content: "\ea9b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-truck_g .path6:before {
  content: "\ea9c";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-truck_g .path7:before {
  content: "\ea9d";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-truck_g .path8:before {
  content: "\ea9e";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-truck_g_1 .path1:before {
  content: "\ea9f";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-truck_g_1 .path2:before {
  content: "\eaa0";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_g_1 .path3:before {
  content: "\eaa1";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_g_1 .path4:before {
  content: "\eaa2";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_g_1 .path5:before {
  content: "\eaa3";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_g_1 .path6:before {
  content: "\eaa4";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_g_1 .path7:before {
  content: "\eaa5";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-truck_g_1 .path8:before {
  content: "\eaa6";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_b .path1:before {
  content: "\eaa7";
  color: rgb(255, 255, 255);
}
.icon-unloading_b .path2:before {
  content: "\eaa8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-unloading_b .path3:before {
  content: "\eaa9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-unloading_b .path4:before {
  content: "\eaaa";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-unloading_b .path5:before {
  content: "\eaab";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-unloading_b .path6:before {
  content: "\eaac";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-unloading_b .path7:before {
  content: "\eaad";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_c .path1:before {
  content: "\eaae";
  color: rgb(0, 0, 0);
}
.icon-unloading_c .path2:before {
  content: "\eaaf";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_c .path3:before {
  content: "\eab0";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_c .path4:before {
  content: "\eab1";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_c .path5:before {
  content: "\eab2";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_c .path6:before {
  content: "\eab3";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_c .path7:before {
  content: "\eab4";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_g .path1:before {
  content: "\eab5";
  color: rgb(255, 255, 255);
}
.icon-unloading_g .path2:before {
  content: "\eab6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-unloading_g .path3:before {
  content: "\eab7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-unloading_g .path4:before {
  content: "\eab8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-unloading_g .path5:before {
  content: "\eab9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-unloading_g .path6:before {
  content: "\eaba";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-unloading_g .path7:before {
  content: "\eabb";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-unloading_g_1 .path1:before {
  content: "\eabc";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-unloading_g_1 .path2:before {
  content: "\eabd";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_g_1 .path3:before {
  content: "\eabe";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_g_1 .path4:before {
  content: "\eabf";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_g_1 .path5:before {
  content: "\eac0";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_g_1 .path6:before {
  content: "\eac1";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-unloading_g_1 .path7:before {
  content: "\eac2";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_b .path1:before {
  content: "\eac3";
  color: rgb(255, 255, 255);
}
.icon-vessel_b .path2:before {
  content: "\eac4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vessel_b .path3:before {
  content: "\eac5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vessel_b .path4:before {
  content: "\eac6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vessel_b .path5:before {
  content: "\eac7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vessel_b .path6:before {
  content: "\eac8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vessel_b .path7:before {
  content: "\eac9";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_b .path8:before {
  content: "\eaca";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_b .path9:before {
  content: "\eacb";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_b .path10:before {
  content: "\eacc";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_c .path1:before {
  content: "\eacd";
  color: rgb(0, 0, 0);
}
.icon-vessel_c .path2:before {
  content: "\eace";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_c .path3:before {
  content: "\eacf";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_c .path4:before {
  content: "\ead0";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_c .path5:before {
  content: "\ead1";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_c .path6:before {
  content: "\ead2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vessel_c .path7:before {
  content: "\ead3";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_c .path8:before {
  content: "\ead4";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_c .path9:before {
  content: "\ead5";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_c .path10:before {
  content: "\ead6";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_g .path1:before {
  content: "\ead7";
  color: rgb(0, 0, 0);
}
.icon-vessel_g .path2:before {
  content: "\ead8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vessel_g .path3:before {
  content: "\ead9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vessel_g .path4:before {
  content: "\eada";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vessel_g .path5:before {
  content: "\eadb";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vessel_g .path6:before {
  content: "\eadc";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-vessel_g .path7:before {
  content: "\eadd";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-vessel_g .path8:before {
  content: "\eade";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-vessel_g .path9:before {
  content: "\eadf";
  margin-left: -1em;
  color: rgb(107, 119, 140);
}
.icon-vessel_g_1 .path1:before {
  content: "\eae0";
  color: rgb(8, 15, 54);
  opacity: 0.13;
}
.icon-vessel_g_1 .path2:before {
  content: "\eae1";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_g_1 .path3:before {
  content: "\eae2";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_g_1 .path4:before {
  content: "\eae3";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_g_1 .path5:before {
  content: "\eae4";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_g_1 .path6:before {
  content: "\eae5";
  margin-left: -1em;
  color: rgb(213, 219, 225);
}
.icon-vessel_g_1 .path7:before {
  content: "\eae6";
  margin-left: -1em;
  color: rgb(213, 219, 225);
}
.icon-vessel_g_1 .path8:before {
  content: "\eae7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-vessel_g_1 .path9:before {
  content: "\eae8";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_g_1 .path10:before {
  content: "\eae9";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_g_1 .path11:before {
  content: "\eaea";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
.icon-vessel_g_1 .path12:before {
  content: "\eaeb";
  margin-left: -1em;
  color: rgb(8, 15, 54);
}
