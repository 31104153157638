@mixin matIconButtonSize($selector, $buttonSize, $iconSize) {
  #{$selector} {
     width: $buttonSize !important;
     height: $buttonSize !important;
     padding: 0px !important;
     display: inline-flex !important;
     align-items: center;
     justify-content: center;

     & > *[role=img] {
        width: $iconSize;
        height: $iconSize;
        font-size: $iconSize;

        svg {
           width: $iconSize;
           height: $iconSize;
        }
     }

     .mat-mdc-button-touch-target {
        width: $buttonSize !important;
        height: $buttonSize !important;
     }
  }
}

//Typography

@mixin headline1() {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: normal;
  letter-spacing: -0.0275rem;
}

@mixin headline2() {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: normal;
  letter-spacing: -0.0225rem;
}

@mixin subtitle1() {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: normal;
  letter-spacing: -0.0225rem;
}

@mixin subtitle2() {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: normal;
  letter-spacing: -0.02rem;
}

@mixin body1() {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: normal;
  letter-spacing: -0.02rem;
}

@mixin body2() {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: normal;
  letter-spacing: -0.0175rem;
}

@mixin body3() {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: -0.015rem;
}

@mixin label() {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: normal;
  letter-spacing: -0.0175rem;
}

@mixin caption1() {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: -0.015rem;
}

@mixin caption2() {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: normal;
  letter-spacing: -0.0125rem;
}

@mixin tab() {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 0.5rem;
  line-height: normal;
  letter-spacing: -0.01rem;
}
