.mdc-button {
  &[mat-flat-button] {
    &:active {
      .mat-mdc-button-persistent-ripple {
        &::before {
          opacity: 0.04;
        }
      }
    }
  }
}
