$color-background: #1ab8d1;
$color-content-background: #e7ecfa;
$color-white: #ffffff;
$color-lightgrey: #fafafa;
$color-grey-dark: #6b778c;
$color-grey: #dfe1e6;
$color-border-input: #c2c3c4;
$color-border-light: #ccd2d8;
$color-black: #303841;
$color-dark-black: #000000;
$color-green-light: #d8fccd;
$color-green-blue: #6ddda3;
$color-blue-light: #b3eff7;
$color-blue-dark: #080f36;
$color-turquoise: #00bbd4;
$color-active: #a2ee8b;
$color-yellow: #ffed15;

$color-button: #9aeb8e;
$color-tab-active: #71a480;
$color-red: #d3575b;
$color-blue: #00bbd4;
$color-blue-100: #f9faff;
$color-green: #1dbf94;
$color-error: #f44336;
$color-orange: #f4882c;
$color-transparent-green: rgba(51, 170, 51, 0.4);
$color-transparent-red: rgba(255, 0, 0, 0.4);
$color-cookie-button: #1f8389;
$color-cookie-button-hover: #3a8641;

$color-gradient-horizontal: linear-gradient(
  to right,
  $color-blue 0%,
  $color-green-blue 35%,
  $color-green 100%
);
$color-gradient-horizontal-revert: linear-gradient(
  to right,
  $color-green 0%,
  $color-green-blue 35%,
  $color-blue 100%
);

$button-gradient: linear-gradient(91.57deg, #a2ee8b 0%, #00bbd4 50.47%);
$button-icon-gradient: linear-gradient(
  134.58deg,
  #a2ee8b 7.09%,

  #00bbd4 81.17%
);

$shadow: 0px 0px 4px 0px #0000001a;

// override material colors for inputs
// $admcs-button-palette: (
//   50: $color-button,
//   100: $color-button,
//   200: $color-button,
//   300: $color-button,
//   400: $color-button,
//   500: $color-button,
//   600: $color-button,
//   700: $color-button,
//   800: $color-button,
//   900: $color-button,
//   A100: $color-button,
//   A200: $color-button,
//   A400: $color-button,
//   A700: $color-button,
//   contrast: (
//     50: #000000,
//     100: #000000,
//     200: #000000,
//     300: #000000,
//     400: #000000,
//     500: #ffffff,
//     600: #ffffff,
//     700: #ffffff,
//     800: #ffffff,
//     900: #ffffff,
//     A100: #000000,
//     A200: #000000,
//     A400: #000000,
//     A700: #000000,
//   ),
// );

// $admcs-white-palette: (
//   50: $color-background,
//   100: $color-background,
//   200: $color-background,
//   300: $color-background,
//   400: $color-background,
//   500: $color-background,
//   600: $color-background,
//   700: $color-background,
//   800: $color-background,
//   900: $color-background,
//   A100: $color-background,
//   A200: $color-background,
//   A400: $color-background,
//   A700: $color-background,
//   contrast: (
//     50: #000000,
//     100: #000000,
//     200: #000000,
//     300: #000000,
//     400: #000000,
//     500: #ffffff,
//     600: #ffffff,
//     700: #ffffff,
//     800: #ffffff,
//     900: #ffffff,
//     A100: #000000,
//     A200: #000000,
//     A400: #000000,
//     A700: #000000,
//   ),
// );

// Material design palette for Angular Material
// http://mcg.mbitson.com/#!?primary=%23005db9&warn=%23bc003a&themename=digisco
$mat-app: (
  50: #ebf7fa,
  100: #cdecf3,
  200: #ace0ec,
  300: #8ad3e4,
  400: #71c9de,
  500: #58c0d8,
  600: #50bad4,
  700: #47b2ce,
  800: #3daac8,
  900: #2d9cbf,
  A100: #fff,
  A200: #cef3ff,
  A400: #9be6ff,
  A700: #81e0ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

$mat-app-accent: (
  50: #f4fdf1,
  100: #e3fadc,
  200: #d1f7c5,
  300: #bef3ae,
  400: #b0f19c,
  500: #a2ee8b,
  600: #9aec83,
  700: #90e978,
  800: #86e76e,
  900: #75e25b,
  A100: #ffffff,
  A200: #ffffff,
  A400: #e8ffe3,
  A700: #d3ffc9,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);
