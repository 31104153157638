@import "../variables/variables";

mat-dialog-container.mat-mdc-dialog-container {
  padding: 0;
  border-radius: 16px;
  overflow: hidden;

  .mat-mdc-dialog-title {
    background: $color-blue-dark;
    color: $color-white;
    padding: 0.5rem 1rem;
    font-size: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::before {
      display: none;
    }

    button.btn-close {
      background: none;
      border: none;
      color: $color-white;

      .mat-icon {
        font-size: 18px;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }

  .mat-mdc-dialog-content {
    padding: 1.5rem;
    width: 100%;
  }

  .mat-mdc-dialog-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
}

.modal-icon {
  font-size: 24px;
}

@media (min-width: 784px) {
  mat-dialog-container.mat-mdc-dialog-container {
    min-width: 844px;
  }
}
