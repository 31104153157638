@import "src/assets/styles/variables/variables";

.mat-mdc-table {
  .mat-mdc-header-row,
  .mat-mdc-row {
    border-color: $color-lightgrey;
  }

  .mat-mdc-header-row {
    height: 2.5rem;
    background-color: $color-lightgrey;

    .mat-mdc-header-cell {
      color: $color-grey-dark;
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  .mat-mdc-row {
    height: 2.75rem;

    &[ng-reflect-router-link] {
      cursor: pointer;
      transition: background-color 200ms ease-in-out;

      &:hover {
        background-color: $color-lightgrey;
      }
    }

    .mat-mdc-cell {
      font-size: 0.875rem;
      font-weight: 400;
      color: $color-blue-dark;
    }
  }

  @include matIconButtonSize(".delete", 2rem, 1.25rem);
}

.mat-mdc-paginator {
  background-color: $color-lightgrey;
}
