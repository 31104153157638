.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    padding: 0;
  }
}

.mat-mdc-menu-panel.notif-menu  {
	margin-top: 0.5rem;

  scrollbar-width: auto;
  scrollbar-color: $color-blue $color-white;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $color-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-blue;
    border-radius: 15px;
    border: 1px solid $color-blue;
  }

	.mat-mdc-menu-content {
		background-color: $color-white;
		width: unset;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
}